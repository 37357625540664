<template>
	<div class="router-view-wrapper">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			{{ help_item.title }}
		</h1>
		<br>
		<div class="q-px-lg">
			<div class='help-item-description q-mb-md'>
				{{ help_item.description }}
			</div>
			<div v-if="media_files">
				<div v-for="file in media_files" :key="file.title">
					<div v-if="file.help_media_type.indexOf('pdf') != -1" class="q-my-md">
						<q-btn
							outline
							icon='picture_as_pdf'
							color="primary"
							style="max-width:100%; width:80%"
							:label="file.help_file_name"
							:href="file.help_media_file"
						/>
					</div>
						<component
							v-else
							class="q-my-md"
							style="max-width:100%; width:80%"
							:is='getTag(file.help_media_type)'
							:src="file.help_media_file"
							controls
						></component>
				</div>
			</div>
		</div>
		<div class="field-fab-wrapper q-pa-sm">
			<q-btn-group outline class="buttons-possition">
				<q-btn flat color="primary" label="Back" icon="navigate_before" :disabled="!prev_item_id || disable_left_button" @click="prev_item"/>
				<q-btn flat color="primary" label="Next" icon-right="navigate_next" :disabled="!next_item_id || disable_right_button" @click="next_item"/>
			</q-btn-group>
		</div>
	</div>
</template>

<script>
import router from '@/router.js';
import HelpAndSupportAPI from '@/services/api/help&support.js';

export default {
	name: "HelpItemDetails",
	data() {
		return {
			help_item: {},
			media_files: {},
			help_items_order: {},
			current_item_id: 0,
			next_item_id: 0,
			prev_item_id: 0,
			next_story_id: 0,
			prev_story_id: 0,
			windowWidth: window.innerWidth,
			disable_right_button: false,
			disable_left_button: false
		};
	},
	methods: {
		async loadData() {
			let response = await HelpAndSupportAPI.loadData(this.$route.params.id);
			this.help_item = response.help_item;
			this.media_files = response.media_files;
			this.help_items_order = response.help_items_order;

			this.current_item_id = this.help_items_order.indexOf(parseInt(this.help_item.id));
			this.next_item_id = (this.help_items_order[this.current_item_id + 1]);
			this.prev_item_id = (this.help_items_order[this.current_item_id - 1]);

			if(this.prev_item_id){
				response = await HelpAndSupportAPI.loadData(this.prev_item_id);
				this.prev_story_id = response.help_item.help_story;
			}
			if(this.next_item_id ){
				response = await HelpAndSupportAPI.loadData(this.next_item_id);
				this.next_story_id = response.help_item.help_story;
			}

		},
		next_item() {
			if (this.next_item_id) {
				this.disable_right_button=true
				router.push({ name: "Help & Support / Details", params: { id: this.next_item_id, story: this.next_story_id}});
				setTimeout(() => {
					this.disable_right_button=false
				}, 300)
			}
		},
		prev_item() {
			if (this.prev_item_id) {
				this.disable_left_button=true
				router.push({ name: "Help & Support / Details", params: { id: this.prev_item_id, story: this.prev_story_id}});
				setTimeout(() => {
					this.disable_left_button=false
				}, 300)
			}
		},
		getTag(file) {
			if (file.indexOf('image') != -1){
				return 'img'
			}
			if (file.indexOf('audio') != -1){
				return 'audio'
			}
			if (file.indexOf('video') != -1){
				return 'video'
			}
			return ''
		},
	},
	mounted() {
		window.onresize = () => {
			this.windowWidth = window.innerWidth
		}
	},
	async created() {
		this.loadData();
	},
	watch: {
		'$route.params.id': function () {
			this.loadData();
		}
	}
}
</script>

<style lang="scss" scoped>
.router-view-wrapper {
	padding: 1.5rem 3rem;
	background-color: #fff;
	@media (max-width: 768px) {
		padding: 1rem 0.5rem;
	}
}

.help-item-description {
	line-height: 1.42857143;
	color: #333;
	white-space: pre-line;
	text-align: justify;
}

.field-fab-wrapper {
	position: sticky;
	bottom: 0px;
	z-index: 5;
	width: 100%;
	max-width: 100%;
	background: #fff;
}
.buttons-possition{
	width: 100%;
	max-width: 100%;
	display: flex;
	justify-content: space-between;
}
</style>
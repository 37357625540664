
import axios from 'axios';

export default {
	async getStories(){
		try {
			const response = await axios.get("/api/help/");
			return response.data;
		} catch (error) {
			console.log(error);
		}
	},

	async searchItems(search){
		try{
			const response = await axios.get("/api/help/search/?search=" + search)
			return response;
		}catch(error){
			console.log(error);
		}
	},

	async loadData(params){
		try {
			const response = await axios.get("/api/help/" + params + "/");
			return response.data;
		} catch (error) {
			console.log(error);
		}
	}
}